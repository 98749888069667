/* global $ */

class App
{
    bootstrap;

    constructor()
    {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initFontAwesome();
        this.initMatrixBlocks();
        this.initMenuItems();
        this.animateButtons();
        this.initAos();
        this.stickyHeader();
        this.fileUpload();
        this.slidingHeader();
        this.scrollHide();
        this.fixSliderheight();
        $(window).scroll(() => {
            this.stickyHeader();
        });
        $(window).resize(() => {
            this.stickyHeader();
        });
        $('body').css('opacity', 1);
    }

    initMenuItems()
    {
        $('.child-sub-item')
            .on('mouseover', function(){
                let child_div = $(this).attr('showchild');
                $('.child-of-' + child_div).removeClass('d-none');
                $('.short-description').not('.child-of-' + child_div).addClass('d-none');
            });
        $('.dropdown-toggler').each(function() {
            $(this).mouseover(() => {
                $('.dropdown-toggler').not(this).removeClass('show').attr('aria-expanded', 'false');
                $(this).addClass('show').attr('aria-expanded', 'true');
                let actsub = $(this).next('.submenu');
                $('.submenu').not(actsub).removeClass('show').removeAttr('data-bs-popper');
                $(actsub).addClass('show').attr('data-bs-popper', 'none');
            });
        });
        $('.dropdown-hide').each(function() {
            $(this).mouseover(() => {
                $('.dropdown-toggler').removeClass('show').attr('aria-expanded', 'false');
                $('.submenu').removeClass('show').removeAttr('data-bs-popper');
            });
        });
        $('html').click(function(e) {
            if(!$(e.target).hasClass('close-out'))
            {
                $('.dropdown-toggler').removeClass('show').attr('aria-expanded', 'false');
                $('.submenu').removeClass('show').removeAttr('data-bs-popper');
            }
        });
    }

    animateButtons() {
        $('.btn-animated').each(function() {
            $(this).append("<span></span><span></span><span></span><span></span><span></span><span></span>");
        });
    }

    stickyHeader() {
        let headerHeight = $('.header-main').height();
        let headingHeight = $('.sticky-header').height();
        let introPaddingHeight = $('.intro-padding').height();
        $('.scroll-header-bg').height(headingHeight).css('top', headingHeight + introPaddingHeight);
        $('.sticky-header-bg').height(headingHeight).css('margin-top', - headingHeight);
        let scrollTop = $(window).scrollTop();
        let headerPos = $('main').offset().top - headerHeight;
        $('.sticky-header-color').height(scrollTop - headerPos);
        if ($(window).width() >= 992 && (scrollTop - headerPos) > 81) {
            $('.sticky-header h1.sticky-text').addClass('text-white');
            $('.sticky-header-color').addClass('show');
        }else if ($(window).width() < 992 && (scrollTop - headerPos) > 67) {
            $('.sticky-header h1.sticky-text').addClass('text-white');
            $('.sticky-header-color').addClass('show');
        } else {
            $('.sticky-header h1.sticky-text').removeClass('text-white');
            $('.sticky-header-color').removeClass('show');
        }
        if ($('.sticky-header-height').length) {
            $('.sticky-header-height').height($('.fixed-sticky-header').outerHeight());
        }
    }

    slidingHeader() {
        if ($('.sliding-header').length) {
            let sliderheight = $('.sliding-header').innerHeight();
            let slidertop = $('.sliding-header').offset().top;
            $('.sliding-header').css('top', slidertop);
            $(window).scroll(() => {
                $('.sliding-header').css('top', slidertop-sliderheight);
                clearTimeout($.data(this, 'scrollTimer'));
                $.data(this, 'scrollTimer', setTimeout(function() {
                    $('.sliding-header').css('top', slidertop);
                }, 1000));
            });
        }
    }

    scrollHide() {
        if ($('.sticky-header-two').length) {
            let headerHeight = $('.header-main').height();
            let headingHeight = $('.sticky-header').height();
            $('.sticky-header-two').css('top', headerHeight + headingHeight);
        }
    }

    fixSliderheight() {
        if ($('.mobile-slider.latest-mobile').length) {
            $('.mobile-slider.latest-mobile').each(function() {
                let $this = $(this);
                let $items = $this.find('.latest-content');
                let maxHeight = 0;
                $items.each(function() {
                    if ($(this).height() > maxHeight) {
                        maxHeight = $(this).height();
                    }
                });
                $items.height(maxHeight);
            });
        }
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initMatrixBlocks()
    {
        if ($('.matrix-block').length) {
            import(/* webpackChunkName: "matrixContent" */ './components/matrixContent');
        }
    }

    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms($forms)
    {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem).show();
                });
            });
        }
    }

    initTooltips($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addAlert(message, $elem, type = "danger", dismissible = false)
    {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false)
    {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }

    initAos() {
        $(function () {
            var AOS = require('aos');
            AOS.init({
                offset: 0,
                duration: 700,
                easing: "ease-out-quad",
            });
            window.addEventListener('load', AOS.refresh);
            document.querySelectorAll('img')
                .forEach((img) =>
                    img.addEventListener('load', () =>
                        AOS.refresh()
                    )
                );
        });
    }

    fileUpload() {
        $('.file-upload').each(function() {
            let input = $(this).find('input');
            let label = $(this).find('label');
            $('<span> - Upload File</span>').appendTo(label);
            input.change(() => {
                let file = input.val().split("\\").pop();
                if(file.length > 25){
                    file = file.substr(0,22) + "...";
                }
                label.html(file);
            });
        });
    }
    
}

export default App;
